import React from "react";

import MMCta from "../../../commons/MMCTA";
import SanityRichText from "../../../commons/SanityRichText";
import ReviewsSlider from "./Slider";
import "./styles.scss";

const HomepageReviews = ({data}) => {
  const {
    _rawHeading,
    description,
    ctaText,
    ctaUrl,
    firstReviews,
    secondReviews,
  } = data;

  return (
    <section className='homePageReviews full-bleed'>
      <div className='homePageReviews__left'>
        {_rawHeading ? (
          <SanityRichText
            renderContainerOnSingleChild
            blocks={_rawHeading}
            className='left__heading'
          />
        ) : (
          <h2 className='left__heading'>
            <span>10K+</span> Shopify brands trust ModeMagic to power their
            growth
          </h2>
        )}
        <h3 className='left__subheading'>
          {description ?? "Best rated app for conversions by Shopify & VTEX."}
        </h3>
        {ctaText ? (
          <div className='left__cta'>
            <MMCta
              cta={ctaText ?? "Get Started"}
              href={
                ctaUrl ??
                "https://www.shopify.com/admin/oauth/authorize?client_id=70a074b87b07f9468ceafd018c360396&scope=write_products%2Cwrite_script_tags%2Cwrite_themes%2Cread_discounts%2Cread_orders%2Cread_inventory%2Cread_price_rules&redirect_uri=https%3A%2F%2Fapp.getmodemagic.com%2Finstall%2Fcallback"
              }
              v3
              footer={false}
              id='mm-install-app-reviewcta'
            />
          </div>
        ) : null}
      </div>
      <div className='homePageReviews__right'>
        <ReviewsSlider
          firstReviews={firstReviews}
          secondReviews={secondReviews}
        />
      </div>
    </section>
  );
};

export default HomepageReviews;
