import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Marquee from "react-fast-marquee";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

export const SocialProofBanner = ({data, speed = 100, className = ""}) => {
  return (
    <Marquee speed={speed} className={styler.get("socialProofContainer")}>
      {data?.map((image) => {
        return (
          <div className={styler.get(["socialProofLogo"])}>
            <GatsbyImage
              alt={image?.alt}
              image={image?.image?.asset?.gatsbyImageData}
              className={styler.get([image?.alt ?? ""])}
              objectFit='contain'
            />
          </div>
        );
      })}
    </Marquee>
  );
};
