// extracted by mini-css-extract-plugin
export var bgSvg = "styles-module--bgSvg--G0V5x";
export var embedContainer = "styles-module--embedContainer--3xZa6";
export var headingBox = "styles-module--headingBox--2tnmr";
export var mmWorkflow = "styles-module--mmWorkflow--r5SN6";
export var modemagicOverviewDescription = "styles-module--modemagicOverviewDescription--TqL68";
export var modemagicOverviewHeading = "styles-module--modemagicOverviewHeading--37np3";
export var modemagicOverviewWrapper = "styles-module--modemagicOverviewWrapper--2eE-Y";
export var selectedTabLine = "styles-module--selectedTabLine--12FjC";
export var selectedWorkflow = "styles-module--selectedWorkflow--1HqTo";
export var shine = "styles-module--shine--vtInh";
export var walkthroughWrap = "styles-module--walkthroughWrap--3j_yq";
export var workflowBorder = "styles-module--workflowBorder--3r69Q";
export var workflowCard = "styles-module--workflowCard--2_LV0";
export var workflowCardDescription = "styles-module--workflowCardDescription--2VUr1";
export var workflowCardHeading = "styles-module--workflowCardHeading--2GFJl";
export var workflowCardTab = "styles-module--workflowCardTab--14RlE";
export var workflowDescription = "styles-module--workflowDescription--17-Rc";
export var workflowDetailsContainer = "styles-module--workflowDetailsContainer--1dqRo";
export var workflowHeading = "styles-module--workflowHeading--2-HBC";
export var workflowHeadingSection = "styles-module--workflowHeadingSection--11zZV";
export var workflowImage = "styles-module--workflowImage--1IN-l";
export var workflowMobileImage = "styles-module--workflowMobileImage--2NvLD";
export var workflowSection = "styles-module--workflowSection--3jZbk";
export var workflowSectionMobile = "styles-module--workflowSectionMobile--mQ1q5";
export var workflowTab = "styles-module--workflowTab--WjDWw";
export var workflowTabName = "styles-module--workflowTabName--2nKs5";
export var workflowTabs = "styles-module--workflowTabs--3d_Cc";
export var workflowTabsContainer = "styles-module--workflowTabsContainer--3hZac";