import React from "react";

export const ArrowRightIcon = ({
  className,
  onClick,
  size = 36,
  color = "#808693",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      color={color}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05887 0 0 8.05888 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.1969 16.5436C23.6427 15.9894 22.744 15.9894 22.1898 16.5436L17.3997 21.3337C16.8454 21.888 16.8454 22.7866 17.3997 23.3409C17.954 23.8951 18.8526 23.8951 19.4069 23.3409L24.1969 18.5508C24.7512 17.9965 24.7512 17.0979 24.1969 16.5436Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.1959 18.5482C23.6428 19.1037 22.7442 19.1056 22.1887 18.5525L17.4286 13.8128C16.8731 13.2597 16.8712 12.361 17.4243 11.8056C17.9774 11.2501 18.876 11.2482 19.4314 11.8013L24.1916 16.541C24.747 17.0941 24.7489 17.9927 24.1959 18.5482Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.0156 17.5467C23.0156 18.3305 22.3802 18.9659 21.5963 18.9659L10.4195 18.9659C9.63566 18.9659 9.00022 18.3305 9.00022 17.5466C9.00022 16.7628 9.63566 16.1274 10.4195 16.1274L21.5963 16.1274C22.3802 16.1274 23.0156 16.7628 23.0156 17.5467Z'
        fill='white'
      />
    </svg>
  );
};

export const ArrowLeftIcon = ({
  className,
  onClick,
  size = 36,
  color = "#808693",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      color={color}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 36C8.05887 36 0 27.9411 0 18C0 8.05888 8.05887 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8031 16.5436C12.3573 15.9894 13.256 15.9894 13.8102 16.5436L18.6003 21.3337C19.1546 21.888 19.1546 22.7866 18.6003 23.3409C18.046 23.8952 17.1474 23.8952 16.5931 23.3409L11.8031 18.5508C11.2488 17.9965 11.2488 17.0979 11.8031 16.5436Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8041 18.5482C12.3572 19.1037 13.2558 19.1056 13.8113 18.5525L18.5714 13.8128C19.1269 13.2597 19.1288 12.361 18.5757 11.8056C18.0227 11.2501 17.124 11.2482 16.5686 11.8013L11.8084 16.541C11.253 17.0941 11.2511 17.9928 11.8041 18.5482Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9844 17.5467C12.9844 18.3305 13.6198 18.9659 14.4037 18.9659L25.5805 18.9659C26.3643 18.9659 26.9998 18.3305 26.9998 17.5466C26.9998 16.7628 26.3643 16.1274 25.5805 16.1274L14.4037 16.1274C13.6198 16.1274 12.9844 16.7628 12.9844 17.5467Z'
        fill='white'
      />
    </svg>
  );
};
