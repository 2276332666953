import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Layout from "../../components/Layout";
import {ModemagicOverviewSection} from "../../components/ModemagicOverviewSection";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import MMHero from "../../components/pages/modemagic/Hero";
import HomepageReviews from "../../components/pages/modemagic/HomepageReviews";
import {SocialProofBanner} from "../../components/pages/modemagic/SocialProofBanner";
import MMStickyCTA from "../../components/pages/modemagic/StickyCTA";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const ModeMagicOverview = ({data: cmsData}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {
    hero = {},
    hpReviews,
    lastSection,
  } = cmsData.allSanityModemagicPage.nodes[0] ?? {};
  const {hero: modemagicOverviewHero = {}, modemagicWorkflowSection} =
    cmsData.allSanityModemagicOverviewPage.nodes[0] ?? {};
  const {pwEmbedUrl} = cmsData.allSanityProductWalkthroughPage.nodes[0] ?? {};

  return (
    <Layout type='modemagic' seoData={seoData} mmv3>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{
          name: "ModeMagic",
          key: "modemagic",
        }}
      />
      <div className='mobileSocialProofHomepage'>
        {isMobile && <SocialProofBanner data={hero.socialProofHomepage} />}
      </div>
      <MMHero
        theme={{
          heroImage: styler.get(["heroImageModemagic"]),
        }}
        data={modemagicOverviewHero}
        v3
      />
      {!isMobile && (
        <div className='full-bleed homepageDesktopBanner'>
          <SocialProofBanner
            data={[...hero.socialProofHomepage, ...hero.socialProofHomepage]}
          />
        </div>
      )}
      <section className='full-bleed'>
        <ModemagicOverviewSection
          data={modemagicWorkflowSection}
          pwEmbedUrl={pwEmbedUrl}
          isMobile={isMobile}
        />
      </section>
      {!isMobile && (
        <section className='full-bleed mmColoredBackground'>
          <HomepageReviews data={hpReviews} />
        </section>
      )}
      <section className='footerWrap full-bleed mmColoredBackground'>
        <LastSection data={lastSection} hideTicketSectionInDesktop />
      </section>
      {isMobile && (
        <MMStickyCTA
          data={hero.cta}
          mobileCTAText={hero.mobileAuditCTA?.text ?? ""}
          mobileCTAUrl={hero.mobileAuditCTA?.url ?? ""}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "modemagic"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityHomepage {
      nodes {
        logos {
          logo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          name
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        hero {
          socialProofHomepage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
        hpReviews {
          ctaText
          ctaUrl
          _rawHeading
          description
          firstReviews {
            reviewText
            reviewerCompany
            reviewerDesignation
            reviewerName
            reviewerImage {
              alt
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
          secondReviews {
            reviewText
            reviewerCompany
            reviewerDesignation
            reviewerName
            reviewerImage {
              alt
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    allSanityModemagicOverviewPage {
      nodes {
        hero {
          _rawHeading
          _rawDescription
          _rawMobileDescription
          cta {
            text
            responseText
            action
            url
            mobileRedirectUrl
          }
          _rawAuditCtaText
          auditCTA {
            url
          }
          mobileAuditCTA {
            text
            url
          }
          belowCta
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          mobileImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          mobileAlt
          video {
            type
            showThumbnail
            ytId
            file {
              asset {
                url
              }
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
          }
          show
        }
        modemagicWorkflowSection {
          heading
          _rawDescription
          modemagicWorkflows {
            workflowTabName
            workflowHeading
            _rawWorkflowDescription
            workflowImage {
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    allSanityProductWalkthroughPage {
      nodes {
        pwEmbedUrl
      }
    }
  }
`;

export default ModeMagicOverview;
