import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import {ArrowRightIcon} from "../../../icons/Arrows";
import "./styles.scss";

const ReviewCard = ({
  reviewText,
  reviewerImage,
  reviewerName,
  reviewerDesignation,
  reviewerCompany,
}) => {
  const {alt, image} = reviewerImage;
  return (
    <div className='cardContainer'>
      <p className='reviewText'>{`"${reviewText}"`}</p>
      <div className='reviewer'>
        <GatsbyImage
          image={image?.asset?.gatsbyImageData}
          alt={alt}
          className='reviewerImage'
        />
        <div className='reviewerDescription'>
          <div className='reviewerName'>{reviewerName}</div>
          <div>{reviewerDesignation}</div>
          <div>{reviewerCompany}</div>
        </div>
      </div>
    </div>
  );
};

const ReviewsSlider = ({firstReviews, secondReviews}) => {
  const fSlides = firstReviews.map((cardData) => <ReviewCard {...cardData} />);
  const sSlides = secondReviews.map((cardData) => <ReviewCard {...cardData} />);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const settings = {
    dots: false,
    infinite: true,
    speed: 12000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1,
    className: "sliderReviewsHomepage",
    arrows: isMobile,
    nextArrow: <ArrowRightIcon color='#CCCFD4' />,
    prevArrow: <ArrowRightIcon color='#CCCFD4' />,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          autoplay: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className='homepagereview-wrap'>
      <div>
        <div className='rev-slider w-full flex flex-col md:items-center justify-between'>
          {isMobile ? (
            <>
              <div className='w-full md:h-full relative'>
                <Slider {...settings}>{[...fSlides, ...sSlides]}</Slider>
              </div>
            </>
          ) : (
            <>
              <div className='shift-left w-full md:h-full relative'>
                <Slider {...settings}>{fSlides}</Slider>
              </div>
              <div className='w-full md:h-full relative mt-12'>
                <Slider {...{...settings, slidesToScroll: -1}}>
                  {sSlides}
                </Slider>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewsSlider;
